.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background: linear-gradient(to right, #a0f8bf, #2af155, #c432f5, #e99aff); 
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-between; /* Añade espacio entre los elementos */

  padding: 3%;
  color: white;
  font-size: 36px;
  font-weight: bold;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.body {
  display: flex;
  flex-direction: column;
  align-items: initial;
  justify-content: center;

  margin-top: 1%;
  margin-right: 1.25%;
  margin-left: 1.25%;
  
  
  border: 1px solid #000000; 
  border-radius: 0px; 
  border-color: black;
}
.StackClass{  
  padding-top: 1% !important;
  padding-bottom: 1%;
}